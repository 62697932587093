import React from 'react';
import AccountBadge from '../../../common/components/AccountBadge'
import Icon from '../../../common/components/Icon';
import Icons from '../../../base/icons';

export default
class MapHeader extends React.Component{
  render() {
    return(
      <header className={this.props.className} style={{ zIndex: 1001 }}>
        <Icon svgName={Icons.LOGO}/>
      <h1 class="titelh1" title="Building an optimal (short, fast) route through several points, planning the path and calculating distances">Building an optimal route across multiple points</h1>
      {/* <nav class="nav" id="nav">
        <ul class="toggle">
          <li><a href="/poncy/index.html#top_content">О проекте</a></li>
          <li><a href="/poncy/index.html#service">Как это работает</a></li>
          <li><a href="/poncy/index.html#Portfolio">Библиотека</a></li>
          <li><a href="/poncy/index.html#pricing">Тарифы</a></li>
        </ul>
        <ul>
          <li><a href="/poncy/index.html#top_content">О проекте</a></li>
          <li><a href="/poncy/index.html#service">Как это работает</a></li>
          <li><a href="/poncy/index.html#Portfolio">Библиотека</a></li>
          <li><a href="/poncy/index.html#pricing">Тарифы</a></li>
        </ul>
      </nav> */}
        <AccountBadge/>
      </header>
      
    );
  }
}