import React from "react";
import { Button } from "common/components";
import Icons from "base/icons";
import ControlsCourierItem from "./ControlsCourierItem";
import I18N from "../../../base/i18n";

const ControlsCouriers = (props) => {
  const renderRows = (agents) => {
    return Object.values(agents).map((agent) => (
      <ControlsCourierItem
        {...props}
        key={agent.id}
        agent={agent}
        default={props.default}
      />
    ));
  };
  //
  return (
    <>
      <div style={
        { margin: "10px auto auto auto", display: "flex",
        flexDirection: "row", justifyContent: "center" 
        }}>
        {/* <a href="https://t.me/poncy_courier_bot" target="_blank">
          <Button
            icon={Icons.TELEGRAM_LOGO}
            className="NewBtn"
            toolTip={I18N.CONTROLS_AGENT_TELERGAM_TIP}
          ></Button>
        </a> */}
        <Button icon={Icons.POINT_ADD} onClick={() => props.addAgent()}>
          { I18N.COURIER_ADD }
        </Button>
      </div>
      {renderRows(props.agents)}
    </>
  );
};

export default ControlsCouriers;
