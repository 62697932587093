export const I18N_en = {
    _lang: 'en',
    _locale: 'en-US',
    
    SUBMIT_DEFAULT: 'Done',
    SUBMIT_SAVE: 'Save',
    SUBMIT_CREATE: 'Create',
    SUBMIT_SEND: 'Send',
    SUBMIT_CANCEL: 'Cancel',
    SUBMIT_CONFIRM: 'Confirm',
    SUBMIT_FEEDBACK: 'Feedback',
    SUBMIT_FEEDBACK_BOTTOM: 'Feedback or suggestion',
    SUBMIT_PROGRESS: 'Sending...',
    SUBMIT_FORWARD: 'Forward',
    SUBMIT_BACK: 'Back',
    SUBMIT_DOWNLOAD_POINTS: 'Upload the entered addresses',
    SUBMIT_LOAD_EXCEL_POINTS: 'Points from Excel',
    SUBMIT_DOWNLOAD_FILE_TEMPLATE: 'Download template',
    
    CLOSE: 'Close',
    CONTROLS_NAV_ROUTE: 'Route',
    CONTROLS_NAV_PERSONAL_AREA: 'Personal Account',
    CONTROLS_NAV_ABOUT_SERVICE: 'About the service',
    CONTROLS_HEADER_ADDRESS: 'Addresses',
    CONTROLS_HEADER_COURIERS: 'Couriers',
    CONTROLS_SWITCHER_MANUAL: 'One at a time',
    CONTROLS_SWITCHER_TEXT: 'List',
    CONTROLS_SWITCHER_FILE: 'From file',
    CONTROLS_HEADER_CONDITIONS: 'Set conditions',
    CONTROLS_HEADER_CALCULATION: 'Calculate the route',
    CONTROLS_TEXT_EDITOR_PLACEHOLDER: 'Enter the addresses one by one in the line',
    CONTROLS_AGENT_LIGHT: 'Passenger car',
    CONTROLS_AGENT_HEAVY: 'Truck',
    CONTROLS_AGENT_MAN: 'Foot courier',
    CONTROLS_AGENT_WORKTIME: "Courier's working time",
    // CONTROLS_AGENT_PHONE_TIP: 'Номер телефона необходим для передачи маршрута курьеру в Telegram боте',
    // CONTROLS_AGENT_TELERGAM_TIP: 'Бот Poncy для курьера',  
    CONTROLS_JAMS_ON: 'Consider traffic jams',
    CONTROLS_JAMS_OFF: 'No traffic jams',
    CONTROLS_ADDRESS_ADD: 'Intermediate point',
    CONTROLS_ADDRESS_LIMIT_EXCEEDED: 'You have reached the limit of the free number of addresses. If you are interested in building a route to more addresses, please write to us.',
    CONTROLS_ADDRESS_LIMIT_EXCEEDED_NOREGISTRATION: 'You have reached the address limit without registering. Please register or log in to your account to add more points.',
    CONTROLS_RESET_WORKING_TIME_DESCRIPTION: 'Reset the working time of the point',
    CONTROLS_HEADER_TEXT_EDITOR: 'See each address from a new line',
    CONTROLS_NOT_RETURN_DESCRIPTION: 'Go back to the garage',
    CONTROLS_RETURN_DESCRIPTION: "Don't go back to the garage",
    
    CONTROLS_RUN: 'Build',
    CONTROLS_RUN_LIMIT_EXCEEDED: 'You have reached the settlement limit without registration. Please register or log in to your account to calculate the route.',
    CONTROLS_DEL: 'Delete',
    CONTROLS_YANDEXNAVI_OPEN: 'Yandex.navigator',
    CONTROLS_YANDEXNAVI_TOOLTIP: 'Works on a mobile device with the app installed',
    CONTROLS_SAVE_GPX: 'GPX file',
    CONTROLS_RUN_SUUCCESS: 'The route was built successfully',
    CONTROL_POINT_WORKTIME: 'The operating time of the point (from / to), the time at the point (minutes)',
    CONTROLS_CALCULATING: "Now let's calculate...",
    CONTROLS_ALERT_CLEAR_ADDRESSES: 'Attention! Delete all addresses and calculation results?',
    CONTROLS_ALERT_RUN_TASK: 'Build an optimal route?',
    CONTROLS_ALERT_DOWNLOAD_POINTS: 'Confirm loading of new addresses',
    CONTROLS_REPORT_DOWNLOAD: 'Itinerary',
    CONTROLS_REPORT_PRINT: 'Send to print',
    CONTROLS_REPORT_DESCRIPTION: "The results are calculated taking into account the loading/unloading time at each point of 15 minutes. The courier's departure time is considered the start time of the first point, by default it is set to 8:00.",
    CONTROLS_REMOVE_ALL: 'Clear data',
    CONTROLS_COPYRIGHT: 'Rights reserved © 2020',
    CONTROLS_ADRESS_HINT: '*After downloading, check that the geocoding of each address is correct',
    
    GPX_BUILDER_NAME: 'Route from ',
    
    LOGIN_AUTORATION: 'Authorization',
    LOGIN_LOGIN: 'Login',
    LOGIN_LOGOUT: 'Log out',
    LOGIN_SIGNUP: 'Registration',
    LOGIN_SIGNUP_INFO: 'Welcome! You can use our service for FREE after a quick registration.',
    LOGIN_RESTORE_PASSWORD: 'Recover password',
    LOGIN_WRONG_CREDENTIALS: 'Invalid username or password',
    LOGIN_SIGNUP_FAILURE: 'Registration error, check the entered data',
    LOGIN_AGREEMENT: 'I consent to the processing of my personal data',
    
    MAP_HEADER_TITLE: 'Building an optimal (short, fast) route through several points, planning the path and calculating distances',
    MAP_HEADER_H1: 'Building an optimal route across multiple points',
    
    USER_NAME: 'Login',
    USER_PHONE: 'Phone',
    USER_PASSWORD: 'Password',
    USER_CONFIRM_PASSWORD: 'Confirm password',
    USER_EMAIL: 'E-mail',
    
    POINT_COMMENT: 'Order comment',
    
    COURIER_PHONE: 'Phone',
    COURIER_NAMING: 'Name',
    COURIER_MAX_POINTS: 'Max addresses',
    COURIER_ADD: 'Add a courier',
    COURIER_DELETE: 'Delete Courier',
    
    NOTIFY_ERROR: 'An error has occurred.',
    NOTIFY_SUCCESS: 'Successful.',
    NOTIFY_DELETE: 'Deleting an element.',
    NOTIFY_IMPORT_POINTS_TEMPLATE_ERROR: 'The file is corrupted or the template is filled in incorrectly.',
    
    MAP_MARKER_START: 'Starting point of the route',
    MAP_MARKER_FINISH: 'Destination of the route',
    MAP_MENU_USE_AS_START: 'Starting point',
    MAP_MENU_USE_AS_INTERMEDIATE: 'Add a delivery point',
    MAP_MENU_USE_AS_FINISH: 'Endpoint',
    
    STATS_TITLE: "Results",
    STATS_DISTANCE: 'Distance (km)',
    STATS_DISTANCE_MI: 'Distance (miles)',
    STATS_TIME: 'Travel time',
    STATS_POINTS: 'Addresses',
    
    PERSONAL_AREA_KM: 'Kilometers',
    PERSONAL_AREA_M: 'Miles',
    PERSONAL_AREA_24: '24 hours',
    PERSONAL_AREA_12: 'AM/PM',
    PERSONAL_AREA_DELETE_MANIFEST_TIP: 'Delete a route',
    PERSONAL_AREA_DELETE_MANIFEST_ALERT: 'Delete route "%%"? It will be impossible to restore it.',
    PERSONAL_AREA_LOAD_MANIFEST_TIP: 'Upload a route',
    PERSONAL_AREA_LOAD_MANIFEST_ALERT: 'Download the route "%%"? The current route will be deleted.',
  
    REPORT_PRINT_HEADER: 'Itinerary',
    REPORT_STAT_DATE: 'Date',
    REPORT_STAT_POINTS: 'Total points',
    REPORT_STAT_DISTANCE: 'Path length (km.)',
    REPORT_STAT_TIME: 'Travel time',
    REPORT_SHEET_NAME: 'Route',
    REPORT_ORDER: 'Number',
    REPORT_FIELD_ADDRESS: 'Address',
    REPORT_FIELD_WORK_START: 'Getting started',
    REPORT_FIELD_WORK_END: 'End of work',
    REPORT_ARRIVAL: 'Arrival Time',
    REPORT_DEPARTURE: 'Departure time',
    REPORT_TOTAL_DISTANCE: 'Shared path (km.)',
    REPORT_POINT_COMMENT: "Point comment",
    REPORT_COURIER_COMMENT: "Courier comment",
    REPORT_POINT_STATUS: "Execution status",
    REPORT_POINT_STATUS_0: "No status",
    REPORT_POINT_STATUS_1: "In progress",
    REPORT_POINT_STATUS_2: "Completed",
    REPORT_POINT_STATUS_5: "Cancellation ",
    REPORT_COURIER_NAME: "Name",
    REPORT_COURIER_PHONE: "Phone",
    REPORT_COURIER_WORK_START: "Start of courier shift",
    REPORT_COURIER_WORK_END: "The end of the courier shift",
    
    FAVORITE_ROUTE_TITLE: 'Favorite routes',
    FAVORITE_ROUTE_LOAD_MANIFEST_SUCCESS: 'Route loaded',
    FAVORITE_ROUTE_LOAD_MANIFEST_ERROR: 'Failed to load route',
    FAVORITE_ROUTE_LOAD_MANIFESTS_ERROR: 'Failed to get saved routes',
    FAVORITE_ROUTE_DELETE_MANIFEST_SUCCESS: 'Route deleted',
    FAVORITE_ROUTE_DELETE_MANIFEST_ERROR: 'Error deleting route',
    FAVORITE_ROUTE_NO_MANIFEST: 'No saved routes',
    FAVORITE_ROUTE_ADD_MANIFEST_SUCCESS: 'The route has been added to the personal account.',
    FAVORITE_ROUTE_ADD_MANIFEST_ERROR: 'There are no routes to save. First calculate the route',
    FAVORITE_ROUTE_SAVE_CONFIRM: 'Save the current route?',
    FAVORITE_ROUTE_SAVE_MANIFEST: 'Save',
    
    FILE_TEMPLATE_NAME: 'Point loading template',
    FILE_POINT_NAME: 'Name of the point',
    FILE_POINT_ADDRESS: 'Address',
    FILE_POINT_LAT: 'Latitude',
    FILE_POINT_LON: 'Longitude',
    FILE_POINT_WORK_START: 'Getting started',
    FILE_POINT_WORK_END: 'Finishing work',
    FILE_POINT_SHIPPING_TIME: 'Maintenance time',
    FILE_POINT_COMMENT: 'Comment',
    
    ERROR: "Error",
    // Task queue errors
    ERROR_2000: "Internal task queue error",
    ERROR_2001: "Router did not respond",
    ERROR_2002: "Router error: %message%",
    ERROR_2003: "Route does not contain couriers",
    ERROR_2004: "Route does not contain points",
    
    // Errors inside the client
    ERROR_C001: "Passwords must match",
    ERROR_C002: "The server is not responding, please contact the manager",
    ERROR_C003: 'Invalid username or password',
    ERROR_C004: 'Registration error, check the entered data',
    ERROR_C005: 'Fill in all fields',
    ERROR_C006: 'Registration was successful. Our manager will contact you soon to familiarize you with the possibilities of the service. The user is not active yet.',
    ERROR_C007: 'Register to build a route',
    ERROR_C008: 'You are not logged in.',
    ERROR_C009: 'The calculation cannot be started.',
    ERROR_C010: 'Error in route calculation. Check the data.',
    ERROR_C011: 'Address not found! Enter the correct address.',
    ERROR_C012: 'The address could not be determined by coordinates.',
    ERROR_C013: 'Not all entered addresses are found, check the list of addresses',
    ERROR_C014: 'Invalid email entered',
    
    // Application Server Errors
    ERROR_1000: "Internal application server error",
    ERROR_1001: "User not found or invalid password.",
    ERROR_1001A: "The user with this email was not found.",
    ERROR_1002: "Email was not found",
    ERROR_1003: "Password was not found",
    ERROR_1004: "Unknown data format: %format%, requires \"json\" or \"xml\"",
    ERROR_1005: "Unknown operation: %operation%",
    ERROR_1006: "No access rights to the current manifest",
    ERROR_1007: "No access rights to the current directory",
    ERROR_1008: "Incorrect or missing parameter: %param_name%",
    ERROR_1009: "Multiple application server errors", // {errors: [error]}
    ERROR_1010: "Entity %id% not found",
    ERROR_1011: "The requested task has not been completed",
    ERROR_1012: "The route is already in the process of calculation",
    ERROR_1013: "Prohibited operation",
    ERROR_1014: "This Email has already been registered",
    ERROR_1015: "Specify the phone number",
    ERROR_1016: "Error in report generation",
    ERROR_1017: "The user is blocked, please contact the manager",
    ERROR_1018: "This phone number has already been registered",
    
    // Solver Errors
    ERROR_3000: "Internal error of the calculation module",
    ERROR_3001: "Unknown specification",
    ERROR_3002: "Unknown task",
    ERROR_3003: "Points %point_id% did not get into the cluster",
    ERROR_3004: "Field %field_name% is not filled in (courier: %agent_id%, dot: %point_id%)",
    ERROR_3005: "Unknown zone %zone_id% (courier: %agent_id%, dot: %point_id%)",
    ERROR_3006: "Too many zones in margrut, 63 maximum",
    ERROR_3007: "The zone distribution task requires only one zone at the point %point_id%",
    ERROR_3008: "Number of views. adjacent points must be at least 1",
    ERROR_3009: "The route does not contain warehouses",
    ERROR_3010: "There is no access to the license server",
    ERROR_3011: "License problems",
    ERROR_3012: "IP blocked",
    ERROR_3013: "Exceeding acceptable task limits",
    ERROR_3014: "Unknown point (courier: %agent_id%, dot: %point_id%)",
    ERROR_3015: "Unknown agent (courier: %agent_id%, dot: %point_id%)",

    // Прочее
    TOOLTIP_WORKING_HOURS: "Specify the working time of the point",
    FEEDBACK_SUCCESS: "Your message has been sent",
    ADDRESS_DESTINATION: "Enter the address in the format 'city street house'",
    FEEDBACK_TEXTAREA_PLACEHOLDER: "Your question",
    QUIZ_TOAST_WARN: "Fill in all the necessary fields",
    QUIZ_SEND_SUCCESS: "Thank you for participating in the survey",
    QUIZ_REQUEST_SEND: 'Thank you, your application has been sent!',
    ENTER_NEW_PASSWORD: "Enter a new password",
    RESTORE_PASSWORD_SUCCESS: "Password recovery request sent",
    PASSWORD_CHANGE_SUCCESS: 'You have successfully changed your password',
    PASSWORD_CHANGE_REQUEST: 'Change password',
    PHONE_NUMBER_LABEL: "Phone number",
    PHONE_SEND_SUCCESS: "Your phone has been sent",
    TYPE_PHONE: "Enter phone number",
}