import React from "react";
import block from "bem-cn-lite";
import { PropTypes } from "prop-types";
import { toast } from "react-toastify";

import ControlsAddressEditor from "./ControlsAddressEditor";
import Button from "common/components/Button";
import Icons from "base/icons";
import I18N from "base/i18n";
import AddressPoint from "../../../common/helpers/AddressPoint";
import { Agents, ButtonKinds } from "../../../common/helpers/enums";
import queries from "../../../base/queries";
import ControlsResults from "./ControlsResults";
import ConfirmButton from "../../../common/components/ConfirmButton";
import LoginForm from "../../../common/components/LoginForm";
import WaybillPoint from "../../../common/helpers/WaybillPoint";
import ManifestTask from "../../../common/helpers/ManifestTask";
import PointValidator from "../helpers/PointValidator";

import { AboutService } from "../../others/";
import { yandexEvent } from "../../others/";
import FeedBack from "../../../common/components/FeedBack";
import Offer from "../../others/offer/Offer";
import ControlsPersonalArea from "./ControlsPersonalArea";
import FirstQuiz from "../../../modules/others/FirstQuiz";
import PasswordRestoreForm from "../../others/PasswordRestoreForm";
import ControlsFavoriteRoute from "./ControlsFavoriteRoute";
import ControlsCouriers from "./ControlsCouriers";
import { MAX_FREE_CALCULATION } from "../../../actions";

const b = block("Controls");

export default class Controls extends React.Component {
  static propTypes = {
    token: PropTypes.string,
    points: PropTypes.objectOf(PropTypes.objectOf(AddressPoint).isRequired),
    waybillPoints: PropTypes.objectOf(
      PropTypes.instanceOf(WaybillPoint).isRequired
    ),
    setCurrentManifestID: PropTypes.func,
    setLastStartedManifest: PropTypes.func,
    lastStartedManifestId: PropTypes.number,
    currentManifestID: PropTypes.number,
    resetAddresses: PropTypes.func,
    resetAgents: PropTypes.func,
    showModal: PropTypes.func,
    freeCalculation: PropTypes.number,
    increaseFreeCalculationLimit: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      manualMode: true,
      fileMode: false,
      courierType: Agents.CAR,
      withJams: false,
      ToggleSwitchChecked: false,
      TopSwitcher: "route",
      PasswordRestoreState: false,
      open: false,
      phoneShow: false,
      formid: "",
      courierMode: false
    };
  }

  componentWillMount() {
    this._changePassword();
  }

  componentDidUpdate(prevProps) {
    if (this.props.NavBarStatus !== prevProps.NavBarStatus) {
      this.setState({ TopSwitcher: "route" });
      this.props.setNavBarStatus(undefined);
    }
  }

  _resetAddresses() {
    toast.success(`${I18N.CONTROLS_REMOVE_ALL}. ${I18N.NOTIFY_SUCCESS}`);
    this.props.resetAddresses();
    localStorage.setItem("appListpoints", null);
  }

  _autoriz = () => {
    if (!this.props.token) {
      this.props.showModal(LoginForm);
      return false;
    } else {
      this.props.showModal(FeedBack, {
        formid: "feedbackbottom",
        email: this.props.user.email,
        token: this.props.user.token,
      });
    }
  };

  _toggleJams = () => {
    this.setState((state) => ({
      ...state,
      ToggleSwitchChecked: !state.ToggleSwitchChecked,
      withJams: !state.withJams,
    }));
  };

  _hasInvalidPoints = () => {
    for (let k in this.props.points) {
      if (!PointValidator.isValid(this.props.points[k])) {
        return true;
      }
    }

    return false;
  };

  _nav = () => {
    if (!this._quizIntermediateStage() && this.state.TopSwitcher !== "Passwd") {
      return (
        <nav className={b("Top-Switcher")}>
          <div
            className={b(
              "item",
              this.state.TopSwitcher === "route" && "active"
            )}
            onClick={() => this.setState({ TopSwitcher: "route" })}
          >
            {I18N.CONTROLS_NAV_ROUTE}
          </div>
          {this.props.token && (
            <div
              className={b(
                "item",
                this.state.TopSwitcher === "personal" && "active"
              )}
              onClick={() => this.setState({ TopSwitcher: "personal" })}
            >
              {I18N.CONTROLS_NAV_PERSONAL_AREA}
            </div>
          )}
          {/* <div
            className={b(
              "item",
              this.state.TopSwitcher === "service" && "active"
            )}
            onClick={() => this.setState({ TopSwitcher: "service" })}
          >
            {I18N.CONTROLS_NAV_ABOUT_SERVICE}
          </div> */}
        </nav>
      );
    } else {
      this.state.TopSwitcher === "route" &&
        this.setState({ TopSwitcher: "Quiz" });
    }
  };

  _quizIntermediateStage = () => {
    if (!this.props.token) {
      this.state.TopSwitcher === "Quiz" &&
        this.setState({ TopSwitcher: "route" });
      return false;
    }
    if (!localStorage.getItem("appUserHistory")) return false;
    if (localStorage.getItem("appUserHistory")) {
      let LSData = JSON.parse(localStorage.getItem("appUserHistory"));
      if (!LSData.firstQuizStatus) {
        LSData = { ...LSData, firstQuizStatus: false };
        localStorage.setItem("appUserHistory", JSON.stringify(LSData));
      }
      if (LSData.countRoute > 5 && LSData.firstQuizStatus === false) {
        return true;
      }
      return false;
    }
  };

  _quizFinalStage = (id, Answers) => {
    queries.POST_ANSWER(id, Answers).then((res) => {
      let LSData = JSON.parse(localStorage.getItem("appUserHistory"));
      LSData = { ...LSData, firstQuizStatus: true };
      localStorage.setItem("appUserHistory", JSON.stringify(LSData));
      queries.UPDATE_USER({ firstQuizStatus: true });
      yandexEvent("FirstQuiz", "");
      this.setState({ TopSwitcher: "route" });
      toast.success(`${I18N.QUIZ_SEND_SUCCESS}`);
    });
  };

  _ChangeManualMode = () => {
    this.setState({ manualMode: !this.state.manualMode });
  };

  _ChangeTopSwitcherMode = (mode) => {
    this.setState({ TopSwitcher: mode });
    this.setState({ PasswordRestoreState: false });
  };

  _isFreeCalcsReached = (calcNumber) => {
    return calcNumber > MAX_FREE_CALCULATION;
  };

  getFirstAddressPoint = (points) => {
    for (const id of Object.keys(points)) {
      if (!points[id].garage && points[id].geocoded) {
        return points[id]
      }
    }
  }

  getManifestPoints = (storedPoints) => {
    const firstPoint = this.getFirstAddressPoint(storedPoints);
    return Object.keys(storedPoints).reduce((allPoints, localPointID) => {
      if (storedPoints[localPointID].garage && !storedPoints[localPointID].geocoded) {
        this.props.updatePointAddress(localPointID, firstPoint.address, firstPoint.coords);
        storedPoints[localPointID] = {
          ...storedPoints[localPointID],
          address: firstPoint.address,
          coords: firstPoint.coords,
        }
      }
      return [
        ...allPoints,
        storedPoints[localPointID]
      ]
    }, [])
  }

  _startFreeTask = () => {
    const task = new ManifestTask(
      this.props.agents,
      this.getManifestPoints(this.props.points)
    );
    queries.OPTIMIZATION_START(task, true)
    .then((data) => {
      if (data.id) {
        this.props.setLastStartedManifest(data.id);
        this.props.increaseFreeCalculationLimit();
        // yandexEvent("buildRoute", this.props.points);
      } else return Promise.reject();
    })
    .catch(() => {
      toast.error(I18N.ERROR_C009);
    })
  };

  _authorizationForm = () => {
    let form = "";
    if (localStorage.getItem("appUserHistory")) {
      form = "login";
    } else {
      form = "singup";
    }
    this.props.showModal(LoginForm, {
      loginFormWindow: form,
      caption: I18N.CONTROLS_RUN_LIMIT_EXCEEDED,
    });
  };

  _changePassword = () => {
    const getUrlParams = (url) => {
      if (url) {
        return JSON.parse(
          "{" +
            url
              .split("?")[1]
              .split("&")
              .map((p) =>
                p
                  .split("=")
                  .map((nv) => '"' + decodeURIComponent(nv) + '"')
                  .join(":")
              ) +
            "}"
        );
      }
    };
    const getLocation = window.location.search;
    if (getLocation) {
      let ttt = getUrlParams(getLocation);
      if (ttt.otp) {
        queries.OTP_EMAIL_KEY(ttt.otp).then((res) => {
          if (!res) return;
          if (!res.result) return console.log("Error");
          this.setState({ PasswordRestoreState: true });
          this.setState({ TopSwitcher: "Passwd" });
          this.props.loginUser(res.user, res.token);
        });
      }
    }
  };

  render() {
    // console.log(this.props);
    const hasInvalidPoints = this._hasInvalidPoints();
    // Для тестирование квиза
    //queries.UPDATE_USER( { 'firstQuizStatus': true } );
    //queries.UPDATE_USER( { 'countRoute': 6 } );

    return (
      <main className={b()}>
        {this.state.PasswordRestoreState ? (
          <PasswordRestoreForm
            ChangeTopSwitcherMode={this._ChangeTopSwitcherMode}
            {...this.props}
          />
        ) : (
          ""
        )}

        {this._quizIntermediateStage() ? (
          <FirstQuiz
            quizName="firstquiz"
            quizId={Number(1)}
            finalStage={this._quizFinalStage}
            {...this.props}
          />
        ) : (
          ""
        )}

        {this._nav()}

        {this.state.TopSwitcher === "route" && (
          <section>
            {this.state.manualMode && (
              <div className={b("Wrapper")}>
                {!this.props.lastStartedManifestId ? (
                  this.props.token ? (
                    <ControlsFavoriteRoute
                      ChangeTopSwitcherMode={this._ChangeTopSwitcherMode}
                      {...this.props}
                    />
                  ) : (
                    <Button
                      kind={ButtonKinds.CONFIRM}
                      onClick={this._isFreeCalcsReached(this.props.freeCalculation)
                        ? this._authorizationForm
                        : this._startFreeTask
                      }
                      icon={Icons.CALCULATION_RUN}
                    >
                      {I18N.CONTROLS_RUN}
                    </Button>
                  )
                ) : (
                  <div className={b("Calculating")}>
                    {I18N.CONTROLS_CALCULATING}
                  </div>
                )}
              </div>
            )}

            <div className={b("Wrapper")}>
              <div className={b("SwitcherAdress")}>
                <Button
                  disabled={this.state.courierMode === false}
                  onClick={() => this.setState({ courierMode: false })}
                >
                  {I18N.CONTROLS_HEADER_ADDRESS}
                </Button>
                <Button
                  disabled={this.state.courierMode === true}
                  onClick={() => this.setState({ courierMode: true })}
                >
                  {I18N.CONTROLS_HEADER_COURIERS}
                </Button>
              </div>
            </div>

            { this.state.courierMode ?
              <ControlsCouriers {...this.state} {...this.props}/>
              : <ControlsAddressEditor
              ChangeManualMode={this._ChangeManualMode}
              {...this.state}
              {...this.props}
            /> }

            {this.props.statistics && <ControlsResults {...this.props} />}
            {/* <h3>Обратная связь</h3> */}
            {/* <div className={b("Wrapper")}>
              <Button
                className={b("BtnLimitMassage")}
                icon={Icons.FEEDBACK}
                onClick={this._autoriz}
              >
                {I18N.SUBMIT_FEEDBACK_BOTTOM}
              </Button>
            </div> */}
            {/* {this.props.token && <Offer {...this.props} />} */}
          </section>
        )}

        {this.state.TopSwitcher === "personal" && (
          <section>
            <ControlsPersonalArea
              ChangeTopSwitcherMode={this._ChangeTopSwitcherMode}
              {...this.props}
            />
          </section>
        )}

        {/* {this.state.TopSwitcher === "service" && (
          <section>
            <AboutService />
          </section>
        )} */}

        {/* <div className={b("Copyright")}>
          <a
            href="https://vk.com/bestroute"
            target="_blank"
            rel="noreferrer noopener"
          >
            <VkLogo className="svg_vk" />{" "}
          </a>
          <a
            href="https://ok.ru/poncy"
            target="_blank"
            rel="noreferrer noopener"
          >
            <OkLogo className="svg_ok" />{" "}
          </a>
          {I18N.CONTROLS_COPYRIGHT}

          <a href={'mailto:support@route4me.ru'}>support@route4me.ru</a>
        </div> */}
      </main>
    );
  }
}
