import React from 'react';
import block from 'bem-cn-lite';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';

import I18N from 'base/i18n';
import { ENDPOINTS } from 'base/api';
import icons from 'base/icons';
import { Icon, SubmitFormButton, TextLink, TextInput, PasswordInput } from 'common/components';
import { actions } from '../../actions';
import { yandexEvent } from '../../modules/others';
import queries from '../../base/queries'
import { InitMetric } from '../../modules/others/metrics';

const b = block('LoginForm');

const Actions = {
  LOGIN: 'login',
  SINGUP: 'singup',
  RESTORE: 'restore',
};

class LoginForm extends React.PureComponent {
  static propTypes = {
    actions: PropTypes.object,
    closeModal: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.state = {
      failureCode: null,
      action: this.props.loginFormWindow,
      caption: this.props.loginFormWindow,
      username: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
      processing: false,
      checkbox: true,
    };
  }

  validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  _handleLogin = () => {
    if (!this.state.password || !this.state.email)
      return this.setState({ failureCode: 'C005' });

    this._setProcessing(true);
    fetch(
      ENDPOINTS.login, {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.email.toLowerCase(),
        password: this.state.password,
      }),
    })
      .then((res) => {
        if (!res)
          return this.setState({ failureCode: 'C002' });
        return res.json();
      })
      .then((res) => {
        if (!res)
          return;

        if (!res.result)
          return this.setState({ failureCode: res.code });

        this.setState({ failureCode: null });
        toast.success(`${I18N.LOGIN_AUTORIZATION}. ${I18N.NOTIFY_SUCCESS}`);
        this.props.actions.loginUser(res.user, res.token);
        queries.CURRENT_USER();
        this.props.closeModal();
      })
      .finally(() => this._setProcessing(false))
  }

  _handleRestore = () => {
    if (!this.state.email) return this.setState({ failureCode: 'C005' });
    if (!this.validateEmail(this.state.email) ) return this.setState({ failureCode: 'C014' });
    this._setProcessing(true);
    fetch(
      ENDPOINTS.restore, {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "email": this.state.email.toLowerCase(),
      }),
    })
      .then((res) => {
        if (!res)
          return this.setState({ failureCode: 'C004', });
        return res.json();
      })
      .then((res) => {
        if (res.result === false) {
          if (res.code == 1001) toast.error(`${I18N.ERROR}: ${I18N.ERROR_1001A}`);
          // toast.error(`Ошибка: ${I18N}.${res.code}`);
          return this.setState({ failureCode: res.code });
        }
          toast.success(I18N.RESTORE_PASSWORD_SUCCESS);
          this.props.closeModal();
      })
      .finally(() => this._setProcessing(false))
  }

  _handleSignup = () => {
    if (!this.validateEmail(this.state.email) ) return this.setState({ failureCode: 'C014' });
    if (!this.state.password || !this.state.email || !this.state.phone)
      return this.setState({ failureCode: 'C005' });
    if (!this.state.password || this.state.password !== this.state.confirmPassword)
      return this.setState({ failureCode: 'C001' });

    this._setProcessing(true);
    fetch(
      ENDPOINTS.signup, {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: this.state.email.toLowerCase(),
        password: this.state.password,
        phone: this.state.phone,
        poncy: true,
        region: "us",
      }),
    })
      .then((res) => {
        if (!res)
          return this.setState({ failureCode: 'C004', });
        return res.json();
      })
      .then((res) => {
        if (!res.result)
          return this.setState({ failureCode: res.code });

        toast.success(`${I18N.LOGIN_SIGNUP}. ${I18N.NOTIFY_SUCCESS}`);
        this.props.actions.loginUser(res.user, res.token);
        yandexEvent('registration', '');
        queries.UPDATE_USER(InitMetric);
        this.props.closeModal();
      })
      .finally(() => this._setProcessing(false))
  }

  _onInputChange = (name, value) => {
    this.setState({
      [name]: value,
      failureCode: null,
    });
  };

  _setProcessing = (yes) => {
    this.setState({
      processing: yes
    });
  };

  _renderRestore = () => <div className={b('FieldsBox')} onKeyPress={(e) => this._onEnterPressed(e, 'restore')}>
    <div className={b('Title')}>{I18N.LOGIN_RESTORE_PASSWORD}</div>
    {this.state.failureCode && <div className={b('Validation')}>{I18N[`ERROR_${this.state.failureCode}`]}</div>}
    <div className={b('Input')}>
      <TextInput className={b('Input')}
        placeHolder={I18N.USER_EMAIL}
        name='email'
        value={this.state.email}
        onChangeValue={this._onInputChange}
      />
    </div>
    <div className={b('Submit')}>
      <SubmitFormButton
        onClick={this._handleRestore}
        inProgress={this.state.processing}>
        {I18N.SUBMIT_SEND}
      </SubmitFormButton>
    </div>
  </div>;

  _renderLogin = () => <div className={b('FieldsBox')} onKeyPress={(e) => this._onEnterPressed(e, 'login')}>
  <div className={b('Info')}>{this.props.caption}</div>
    <div className={b('TitleLogin')}>{I18N.LOGIN_AUTORIZATION}</div>
    {this.state.failureCode && <div className={b('Validation')}>{I18N[`ERROR_${this.state.failureCode}`]}</div>}
    <div className={b('Input')}>
      <TextInput className={b('Input')}
        placeHolder={I18N.USER_NAME}
        name='email'
        value={this.state.email}
        onChangeValue={this._onInputChange}
      />
    </div>
    <div className={b('Input')}>
      <PasswordInput className={b('Input')}
        placeHolder={I18N.USER_PASSWORD}
        name={'password'}
        value={this.state.password}
        onChangeValue={this._onInputChange}
      />
    </div>
    <div className={b('Submit')}>
      <SubmitFormButton onClick={this._handleLogin} inProgress={this.state.processing}>
        {I18N.LOGIN_LOGIN}
      </SubmitFormButton>
    </div>
  </div>;

  _renderSingup = () => <div className={b('FieldsBox')} onKeyPress={(e) => this._onEnterPressed(e, 'signup')}>
    <div className={b('Info')}>{this.props.caption || I18N.LOGIN_SIGNUP_INFO}</div>
    <div className={b('Title')}>{I18N.LOGIN_SIGNUP}</div>
    {this.state.failureCode && <div className={b('Validation')}>{I18N[`ERROR_${this.state.failureCode}`]}</div>}
    <div className={b('Input')}>
      <TextInput className={b('Input')}
        placeHolder={I18N.USER_EMAIL}
        name={'email'}
        value={this.state.email}
        onChangeValue={this._onInputChange}
      />
    </div>
    <div className={b('Input')}>
      <TextInput className={b('Input')}
        autoComplete={'off'}
        placeHolder={I18N.USER_PHONE}
        name={'phone'}
        value={this.state.phone}
        onChangeValue={this._onInputChange}
      />
    </div>
    <div className={b('Input')}>
      <PasswordInput className={b('Input')}
        autoComplete={'off'}
        placeHolder={I18N.USER_PASSWORD}
        name={'password'}
        value={this.state.password}
        onChangeValue={this._onInputChange}
      />
    </div>
    <div className={b('Input')}>
      <PasswordInput className={b('Input')}
        autoComplete={'off'}
        placeHolder={I18N.USER_CONFIRM_PASSWORD}
        name={'confirmPassword'}
        value={this.state.confirmPassword}
        onChangeValue={this._onInputChange}
      />
    </div>
    <div className={b('Input')} >
      <input
        type="checkbox"
        checked={this.state.checkbox}
        onChange={() => this.setState({ checkbox: !this.state.checkbox })}
      /> {I18N.LOGIN_AGREEMENT}
    </div>
    <div className={b('Submit')}>
      <SubmitFormButton
        onClick={this._handleSignup}
        inProgress={this.state.processing}
        disabled={!this.state.checkbox}
      >{I18N.LOGIN_SIGNUP}
      </SubmitFormButton>
    </div>
  </div>;

  _onEnterPressed = (e, source) => {
    if (e.key === 'Enter') {
      source === 'login' && this._handleLogin();
      source === 'signup' && this._handleSignup();
      source === 'restore' && this._handleRestore();
    }
  }

  _switchForm = (action) => this.setState({
    action: action,
    failureCode: null,
  })

  render() {
    return (
      <div className={b()}>
        <div className={b('Logo')}>
          <Icon svgName={icons.LOGO} />
        </div>
        {this.state.action === Actions.LOGIN && this._renderLogin()}
        {this.state.action === Actions.SINGUP && this._renderSingup()}
        {this.state.action === Actions.RESTORE && this._renderRestore()}
        <div className={b('Splitter')}></div>
        <div className={b('Extra')}>
          {this.state.action !== Actions.SINGUP && <TextLink onClick={() => this._switchForm(Actions.SINGUP)}>{I18N.LOGIN_SIGNUP}</TextLink>}
          {this.state.action !== Actions.RESTORE && <TextLink onClick={() => this._switchForm(Actions.RESTORE)}>{I18N.LOGIN_RESTORE_PASSWORD}</TextLink>}
          {this.state.action !== Actions.LOGIN && <TextLink onClick={() => this._switchForm(Actions.LOGIN)}>{I18N.LOGIN_LOGIN}</TextLink>}
        </div>
      </div>
    );
  }
}

export default
  connect(
    null,
    (dispatch) => ({
      "actions": bindActionCreators(actions, dispatch)
    })
  )(LoginForm);